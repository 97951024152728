<template>
  <Popover :width="450" ref="msgPopoverRef">
    <template #popBtn>
      <el-badge
        class="notice-badge"
        :value="unreadCount"
        :max="99"
        :hidden="!unreadCount"
      >
        <p class="notice" title="消息提醒"></p>
      </el-badge>
    </template>
    <div class="popoverListBox">
      <MessageInfo @hide="messagePopHide" @setBadge="setBadge"></MessageInfo>
    </div>
  </Popover>
  <span>
    <el-dropdown @command="handleCommand" trigger="click">
      <span class="el-dropdown-link">
        <Avatar
          class="avatar-icon"
          :src="userAvatar"
          :title="userName"
          alt="用户头像"
        />
        <el-icon class="el-icon--down">
          <ArrowDown color="#fff" size="16px" />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item command="personalCenter">个人中心</el-dropdown-item> -->
          <el-dropdown-item v-if="isShowManagement" command="toHome"
            >切换{{ props.isHome ? "后台" : "前台" }}</el-dropdown-item
          >
          <!-- <el-dropdown-item command="privacyManage">管理规定</el-dropdown-item> -->
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </span>
  <div
    class="person-center inline-flex align-center pointer flex-center"
    title="个人中心"
    @click="handleCommand('personalCenter')"
  >
    个人中心
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, getCurrentInstance, ref, unref } from "vue";
import { openNewRouter } from "@/utils";
import Avatar from "@/components/Comment/Avatar";
import Popover from "@/components/Popover";
import MessageInfo from "../messageInfo";
const { proxy } = getCurrentInstance();
const store = useStore();
const router = useRouter();
// 前台共用此组件
const props = defineProps({
  isHome: Boolean,
});

// 前往个人中心
const toPersonalCenter = () => {
  router.push({
    name: "PersonalCenter",
  });
};

// 获取用户名称
const userName = computed(() => {
  return JSON.parse(localStorage.getItem("userInfo"))?.userName ?? "";
});
const userAvatar = computed(() => store.getters.userInfo?.avatar || "");
const unreadCount = ref(0);
// 获取权限内菜单
const kbmsMenu = computed(() => {
  return (
    JSON.parse(localStorage.getItem("kbmsMenu")) || store.state.app.kbmsMenu
  );
});

// 获取是否有查看后台的权限
const isShowManagement = computed(() => {
  return kbmsMenu.value && kbmsMenu.value?.subResource?.length;
});

// 获取菜单的第一个路由地址
const getFirstRoute = (data) => {
  if (!data.routeCode) {
    if (data?.subResource?.length) {
      const noRouteCodeObj = data.subResource.find(
        (item) => !item.routeCode && item.subResource.length
      );
      if (noRouteCodeObj) {
        return getFirstRoute(noRouteCodeObj);
      } else {
        const routeCodeObj = data.subResource.find((item) => item.routeCode);
        return routeCodeObj.routeCode;
      }
    } else {
      return "/404";
    }
  } else {
    return data.routeCode;
  }
};
// 设置消息铃铛的数量
const setBadge = (val) => {
  unreadCount.value = val;
};
// 切换前台
const toClientHome = () => {
  if (props.isHome) {
    if (getFirstRoute(kbmsMenu.value) === "/404") {
      return proxy.$error("该用户无权限切换后台，请联系管理员配置！");
    } else {
      openNewRouter(getFirstRoute(kbmsMenu.value));
    }
  } else {
    openNewRouter("", "ClientHome");
  }
  // props.isHome
  //   ? openNewRouter(getFirstRoute(kbmsMenu.value))
  //   : openNewRouter('', 'ClientHome')
};

// 下拉事件
const handleCommand = (val) => {
  if (val === "personalCenter") {
    toPersonalCenter();
  } else if (val === "toHome") {
    toClientHome();
  } else if (val === "logout") {
    store.dispatch("user/logout");
  }
  // else if (val === 'privacyManage') {
  //   store.dispatch('app/openPrivacyPolicyDialog', true)
  // }
};
const msgPopoverRef = ref();
const messagePopHide = () => {
  unref(msgPopoverRef)?.popHide();
};
</script>
<style lang="scss" scoped>
.notice-badge {
  margin: var(--notice-margin, 14px 16px);
}
.notice {
  width: var(--notice-w, 36px);
  height: var(--notice-h, 36px);
  cursor: pointer;
  background-image: var(--notice-img, url("@/assets/images/common/notice.svg"));
  background-size: cover;
  background-position: center;
}
.avatar-icon {
  width: var(--nav-avatar-size,36px);
  height: var(--nav-avatar-size,36px);
  // margin-top: 11px;
}
.person-center {
    color: var(--person-center-color, #4e5969);
    font-size: 16px;
    text-align: center;
    border-radius: 100%;
    margin-left: 6px;
    padding: 2px 5px;
    box-sizing: content-box;
}
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.el-icon--down {
  color: #262626;
  width: 16px;
  height: 16px;
  margin: 24px 8px;
  vertical-align: top;
  filter: brightness(var(--client-header-icon-color, 0.2));
}
</style>
