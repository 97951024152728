const personalCenterRoutes = [
  {
    path: 'personalCenter',
    name: 'PersonalCenter',
    component: () => import('@/views/personalCenter'),
    redirect: '/client/personalCenter/companyPush',
    meta: { title: '个人中心' },
    children: [
      {
        path: 'companyPush',
        name: 'CompanyPush',
        component: () => import('@/views/personalCenter/companyPush'),
        meta: { title: '公司推送', keepAlive: true, isMenu: true }
      }, {
        path: 'relateMe',
        name: 'RelateMe',
        component: () => import('@/views/personalCenter/relateMe'),
        meta: { title: '与我相关', keepAlive: true, isMenu: true }
      }, {
        path: 'forumPostBar',
        name: 'ForumPostBar',
        component: () => import('@/views/personalCenter/forumPostBar'),
        meta: { title: '论坛贴吧', keepAlive: true, isMenu: true }
      }, {
        path: 'messageCenter',
        name: 'MessageCenter',
        component: () => import('@/views/personalCenter/messageCenter'),
        meta: { title: '消息中心', keepAlive: true, isMenu: true }
      }, {
        path: 'modifyInfo',
        name: 'ModifyInfo',
        component: () => import('@/views/personalCenter/modifyInfo'),
        meta: { title: '修改资料', keepAlive: true, isMenu: true }
      },
      {
        path: 'clientExamCenter',
        name: 'PersonalCenterExamCenter',
        component: () => import('@/views/personalCenter/clientExamCenter'),
        meta: { title: '考试中心', keepAlive: true, isMenu: true }
      },
      {
        path: 'clientStudyCenter',
        name: 'PersonalCenterStudyCenter',
        component: () => import('@/views/personalCenter/clientStudyCenter'),
        meta: { title: '学习中心', keepAlive: true, isMenu: true }
      }

    ]
  }
]

export default personalCenterRoutes